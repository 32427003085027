import Api from './services';
import {showRequestUI, showRequestError, updateCookie, showUI, showErrorMessage, showSuccessMessage} from '../../common/actions';
import { createAction } from '@reduxjs/toolkit';
import { USER, VIEW} from './constants';
import { setEmail, setName } from '../app/actions';
import JSConfetti from 'js-confetti'
import { toast } from 'sonner';
import { baseURL } from '../../common/constants';

export const setPlan = createAction(USER.SET_PLAN);
export const showView = createAction(VIEW.PAYMENT_UI);
export const setUser = createAction(USER.SET);

export const onHandleTransacation = (order) => async (dispatch, getState) => {
    if ('status' in order){
        if (order.status == 'COMPLETED'){
    XMLSerializer
            const userName = `${order.payer.name.given_name} ${order.payer.name.surname}`;
            const { hasUser } = getState().paymentReducer;
            dispatch(showRequestUI(true));
                try {
                    const response = await Api.setOrder(userName, order.payer.address.country_code, order.payer.email_address, order.id, order.status);
                    
                    if(response.data.created && response.data.order){
                        dispatch(setName(userName));
                        dispatch(setEmail(order.payer.email_address));
                        dispatch(showView({ui:VIEW.DISPLAY_ENABLE_LESSON}));
                        const jsConfetti = new JSConfetti();
                        jsConfetti.addConfetti({
                                emojis: ['🔥', '🥳', '💥', '🎉', '🟪', '🧸','🟡','🔵'],
                        });
                        setTimeout(() =>{
                            window.location = `${baseURL}chat`;
                        }, 5000)
                    }else{
                        toast.error("Payment error, try later")
                        dispatch(showView({ui:VIEW.DISPLAY_PAYMENT}));
                    }
                    dispatch(showRequestUI(false));
                } catch (error) {
                    console.log(error);
                    dispatch(showRequestError())
                    dispatch(showRequestUI(false));
                }
            return;
        }
    }

    toast.error("Payment error, try later")
    dispatch(showView({ui:VIEW.DISPLAY_PAYMENT}));
};

export const setPlanUser = (planId) => async (dispatch, getState) => {
    try {
        dispatch(showRequestUI(true));
        const response = await Api.setPlanUser(planId);
        if('plan' in response.data){
            window.location = `${baseURL}payment`;
           // dispatch(setPlan(response.data.plan));
           // dispatch(showView({ui: VIEW.DISPLAY_PAYMENT}))
        }
        if('user' in response.data){
            dispatch(setUser(response.data.user))
        }
        dispatch(showRequestUI(false))
    } catch (error) {
        dispatch(showRequestError())
    }
};


export const getPlan = () => async (dispatch, getState) => {
    try {
        dispatch(showRequestUI(true));
        const response = await Api.getPlan();
        console.log(response);
        if (response.data.plan.id == null ){
            dispatch(showView({ui:VIEW.DISPLAY_PRICES}));
            dispatch(showRequestUI(false));
            return;
        }
        dispatch(setPlan(response.data.plan));
        dispatch(setUser(response.data.user));
        if(!response.data.plan.required){
            dispatch(showView({ui: VIEW.DISPLAY_JOINUS}))
        }else{
            dispatch(showView({ui: VIEW.DISPLAY_PAYMENT}))
        }
        dispatch(showRequestUI(false))
    } catch (error) {
        dispatch(showRequestError());
    }
};