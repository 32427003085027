import { createReducer } from "@reduxjs/toolkit"
import {wiggleNotName, setName, displayLangs} from './actions'
import { VIEW } from "./constants";
import { showUI } from '../../common/actions';

const conversationState = {
   name: '',
   langs: [{id:1, description:'English'}]
};

const conversationViewState = {
    CURRENT_UI: VIEW.JOINUS,
    RESEND_CODE: false,
    INCORRECT_CODE: false,
    NOT_NAME: 0
};

export const conversationReducer = createReducer(conversationState, (builder)=> {
    builder.addCase(setName, (state, action) => {
        state.name = action.payload;
    }).addCase(displayLangs, (state, action) =>{
        state.langs = action.payload.langs;
    });
});

export const conversationViewReducers = createReducer(conversationViewState, (builder) => {
    builder.addCase(showUI, (state, action) =>{ 
        state.CURRENT_UI = action.payload.ui;
    }).addCase(wiggleNotName, (state, action) =>{
        state.NOT_NAME =  state.NOT_NAME + action.payload;
    });
});
