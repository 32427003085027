import { createReducer } from "@reduxjs/toolkit"
import {setCountry, setCountryCode, displayIncorrectCodeUI, enableSendVerificationCode, setPhoneNumber, wiggleNotPhone, setName, setEmail } from './actions'
import { VIEW } from "./constants";
import { showUI } from '../../common/actions';
import { COUNTRIES } from "../../common/constants";
import { displayLesson } from "../home/actions";

const userState = {
   phoneNumber: '',
   country: COUNTRIES[0].title,
   countryCode: COUNTRIES[0].value,
   email: null,
   name: null
}

const lessonState = {
    lessons:[]
}

const joinViewState = {
    CURRENT_UI: VIEW.JOINUS,
    RESEND_CODE: false,
    INCORRECT_CODE: false,
    NOT_PHONE: 0
};

export const userReducer = createReducer(userState, (builder)=> {
    builder.addCase(setCountry, (state, action) => {
        state.country = action.payload.country;
        state.countryCode = action.payload.countryCode; 
    }).addCase(setPhoneNumber, (state, action) => {
        state.phoneNumber = action.payload;
    }).addCase(setName, (state, action) => {
        state.name = action.payload;
    }).addCase(setEmail, (state, action) => {
        state.email = action.payload;
    });
});

export const joinViewReducers = createReducer(joinViewState, (builder) => {
    builder.addCase(showUI, (state, action) =>{ 
        state.CURRENT_UI = action.payload.ui;
    }).addCase(enableSendVerificationCode, (state, action) =>{
        state.RESEND_CODE = action.payload.data;
    }).addCase(displayIncorrectCodeUI, (state, action) => {
        state.INCORRECT_CODE = action.payload;
    }).addCase(wiggleNotPhone, (state, action) =>{
        state.NOT_PHONE =  state.NOT_PHONE + action.payload;
    });
});


export const lessonReducer = createReducer(lessonState, (builder)=>{
    builder.addCase(displayLesson, (state, action) =>{
        state.lessons = action.payload.data
    });
});