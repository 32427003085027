
import React from "react";
import { APP_NAME } from "../../common/constants";
import SectionContainer from "./sectionContainer";
import Icon from "./icon";
import i18n from "./i18n";


export default function Footer() {
  return (
    <div className="relative">
      <SectionContainer className="px-8" >
      <div className="grid items-center max-w-screen-xl grid-cols-1 sm:gap-10 pt-10 mx-auto mt-5 border-t border-gray-100 lg:grid-cols-3">
                <div className="lg:col-span-1 mb-2">
                  <Icon />
      
                  <div className="max-w-md mt-4 text-gray-500 ">
                    <a href='mailto:hola@speqy.co'>hola@speqy.com</a>
                  </div>
                </div>
      
                <div className="text-sm  text-gray-600 ">
                  Copyright © {new Date().getFullYear()} Made with ♥  <a target="_blank" href="https://www.instagram.com/dalttony/">@dalttony</a>
                </div>
          
                
                  <div className="flex flex-wrap w-full lg:ml-0">
                        <a href="/terminos-y-condiciones" className="w-full py-2 text-gray-500 rounded-md 
                     hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none ">
                         {i18n.t('tos')}
                        </a>
                  </div> 
              </div>
      </SectionContainer>
    </div>
  );
}
