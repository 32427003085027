import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid'

export default (props) =>{
    
    return (
        <div onClick={ props.onClick} className="relative cursor-pointer max-w-sm m-2 p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <CheckCircleIcon className='absolute right-0 text-green-500 h-5 w-5 self-center'/>
            <a >
                <h5 className="mb-2 text-2xl font-bold tracking-normal text-[#3B3486] dark:text-white">{props.lesson.title}</h5>
            </a>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">{props.lesson.description}</p>
        </div>
    );
}