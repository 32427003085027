export const secondsFormatMMSS = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
    return `${formattedMinutes}:${formattedSeconds}`;
}


export const dayFormatMMSS = (day) => {

    const hour = math.floor(day / 24);
    const formattedHours = String(hour).padStart(2, '0');
    return `${formattedHours}:${minutesFormatMMSS(minutes)}`;
}

export const hoursFormatMMSS = (hours) => {
    const minutes = 60;
    hours = hours - 1;
    const strHours =  String(hours).padStart(2, '0');
    return `${strHours}:${minutesFormatMMSS(minutes)}`;
}


export const minutesFormatMMSS = (minute) => {
    return secondsFormatMMSS(minute*60);
}


export const form = {
    ele:null,
    verifyEmail: (ele) => {
        this.ele = ele;
        return this;
    }
}