
import SectionContainer from "./sectionContainer";
import React from 'react';
import NumberInput from '../app/numberInput';
import { APP_NAME } from "../../common/constants";
import StartCoversation from   '../app/index';
import {useStore} from 'react-redux';
import Conversation from '../conversation/index';
import i18n from "./i18n";
export default function Hero() {
  const state = useStore();
  return (
    <>
     <div className="blur-[106px] z-0 inset-x-0 right-0 absolute  opacity-60 top-2/3 h-40 bg-gradient-to-br from-cyan-400 to-purple-400"></div>
      <SectionContainer className="flex flex-wrap px-8 justify-center  "> 
        <div className="flex  w-ful md:w-8/12 mb-8 items-center flex-col">
        
          
            <h1 className="text-4xl md:text-5xl font-bold tracking-tight text-gray-800 text-justify md:text-center ">
              <span className="font-bold text-transparent bg-gradient-to-r bg-clip-text   from-app  to-indigo-600" >
                {i18n.t('home.headline.one')} <span className="text-5xl md:text-6xl italic">{i18n.t('home.headline.x')} </span> {i18n.t('home.headline.two')}. {i18n.t('home.headline.three')}</span>
            </h1>
          

            <Conversation />
            
          </div>
        
        
      
      </SectionContainer>      
    </>
  );
}
