
/**
 * Constants for the whole system
 */
/**
 * [assign object to inmutate another obcjet]
 * @type {object}
 */

export const APP_NAME = 'Speqy';
/**
 * Constants reducers type for the actions  of the network 
 * @type {Object}
 */
export const NETWORK = {
	FETCH_REQUEST: "FETCH_REQUEST",
	FETCH_ERROR: "FETCH_ERROR",
	FETCH_SUCCESS: "FETCH_SUCCESS",
};

export const RESPONSE = {
	STATE: "STATE",
	OK:"OK",
	FAIL: "FAIL",
	COOKIE:'COOKIE'
}

export const ERROR_MESSAGES = {
  505:'Server error',
  
}
/**
 * Constant types of the common reducer for displaying messages in the views
 * @type {Object}
 */
export const VIEW = {
	SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
	SET_SUCCESS_MESSAGE: 'SET_SUCCESS_MESSAGE',
	HIDE_ALERT_MESSAGE: 'HIDE_ALERT_MESSAGE',
	USER_IN_SESSION: 'USER_IN_SESSION',
	JOINUS_APP: 'JOINUS_APP',
  SHOW_UI:'SHOW_UI'
};

export const baseURL =  window.location.origin+'/'; //http://127.0.0.1:8000/';
export const FORM = {
	PASSWORD_NOT_MATCH: "PASSWORD_NOT_MATCH",
	EMAIL_NOT_MATCH: "EMAIL_NOT_MATCH",
	FILL_INPUT: "FILL_INPUT"
};

export const COUNTRIES = [
	{
		title: 'Colombia',
		value: 'CO',
		ncode:'+57'
	  },
  {
    title: 'Argentina',
    value: 'AR',
	ncode:'+54'
  },
  
  {
    title: 'Bolivia',
    value: 'BO',
	ncode:'+591'
  },
  
  {
    title: 'Brazil',
    value: 'BR',
	ncode: '+55'
  },
  
  {
    title: 'Chile',
    value: 'CL',
	ncode:'+56'
  },
  
  {
    title: 'Ecuador',
    value: 'EC',
	ncode:'	+593'
  },
  {
    title: 'Mexico',
    value: 'MX',
	ncode:'	+52'
  },
  {
    title: 'Nicaragua',
    value: 'NI',
	ncode:'	+505'
  },
  
  {
    title: 'Paraguay',
    value: 'PY',
	ncode:'+595'
  },
  {
    title: 'Peru',
    value: 'PE',
    ncode: '+'
  },
  {
    title: 'España',
    value: 'ES',
	ncode: '+34'
  },
  {
    title: 'Uruguay',
    value: 'UY',
	ncode: '+598'
  },
  {
    title: 'Venezuela',
    value: 'VE',
	ncode: '+58'
  },
  
] 