import React from "react";
import { APP_NAME } from "../../common/constants";

export default (props) => {
    return <a href="/" className="flex items-center space-x-2 text-2xl font-medium text-[#3B3486] mr-20">
        <span>
          <img
            src="/static/logo.png"
            alt="N"
            width="32"
            height="32"
            className={`w-8 ${props.className}`}
          />
        </span>
        <span>{APP_NAME}</span>
      </a>
}