/**
 * General service uses by our application
 */
import axios from 'axios';
import { baseURL } from './constants';
const fetch = axios.create({baseURL:`${baseURL}api`});

const POST_HEADERS = {
	headers: {
		"Accept": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	credentials: "same-origin"
}

const POST_HEADERS_JSON = {
	method: "POST",
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	credentials: "same-origin"
}

const POST_HEADERS_WITH_FILE = {
	credentials: "same-origin",
	headers: {
		'Content-Type': 'multipart/form-data',
		"Accept": "application/json",
		"X-Requested-With":"XMLHttpRequest"
	}
}

const GET_HEADERS = {
	method: "GET",
	headers: {
		"Accept": "application/json",
		"Content-Type":"application/json",
		"X-Requested-With": "XMLHttpRequest",
	},
	credentials: "include"
}	



/**
 * Set up by default ofr the server api
 * @type {Object}
 */
export default {
	post(module) {
		return async (form) => {
				try {
					let response = await fetch.get('get-csrf');
					POST_HEADERS["headers"]["X-CSRFToken"] = response.data.token;
					return fetch.post(module, form, POST_HEADERS);
				} catch (error) {
					throw new Error(error);
				}
			}
	},
	get(module) {
			return fetch(module, GET_HEADERS);
	},

	getParams(module) {
		return (data) => {
					let params = "";
					if(data){
						params = new URLSearchParams(Object.entries(data));
						data = "?"+params;
					}
					let urlServer = this.apiServer + module;
					return fetch(module+data, GET_HEADERS);
				}
	},

	postFile(module) {
		return data => {
				POST_HEADERS_WITH_FILE["headers"]["X-CSRFToken"] = document.getElementsByName("csrfmiddlewaretoken")[0].value;				
				return fetch.post(module, data, POST_HEADERS_WITH_FILE);
			}
	},
}