import React from "react";
import {useDispatch} from 'react-redux';
import { setPlanUser } from "./actions";
import SectionContainer from '../util/sectionContainer';
import SectionTitle from '../util/sectionTitle';
import i18n from '../util/i18n';

export default (props) => {
    const dispatch = useDispatch();
    const onHandelSelectedPlan  = (planId) => (evt) => {
        dispatch(setPlanUser(planId));
        evt.preventDefault();
        evt.stopPropagation();
    }
    return <div className="mt-9 sm:mt-20 w-96 p-8 bg-white text-center sm:pl-16  rounded-3xl shadow-xl">
            <h1 className="text-black font-semibold text-2xl">Fluency</h1>
            <p className="pt-2 tracking-wide flex items-center justify-center">
            <span className="text-red-600 text-3xl line-through">$35</span>
                <span className="pl-2 material-icons align-middle text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

                </span>
                <span className="text-gray-400 align-top">$ </span>
                <span className="text-3xl font-semibold">20</span>
                <span className="text-gray-400 font-medium">/{i18n.t('month')}</span>
            </p>
            <hr className="mt-4 border-1" />
            <div className="pt-8">
                {/*<p className="font-semibold text-gray-400 text-left">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                        Conversación de <span className="text-black">30 minutos</span>
                    </span>
                </p>*/}
                <p className="font-semibold  text-left flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2 text-black">
                    {i18n.t('price.fluency.optionone')}
                    </span>
                </p>
                <p className="font-semibold text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                    {i18n.t('price.fluency.optiontwo')}
                    </span>
                </p>
                <p className="font-semibold  text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                    {i18n.t('price.fluency.optionthree')}
                        </span>
                </p>

                <p className="font-semibold  text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                        {i18n.t('price.fluency.optionfour')}
                    </span>
                </p>

                <p className="font-semibold  text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                        {i18n.t('price.fluency.optionfive')}
                    </span>
                </p>

                <p className="font-semibold  text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                        {i18n.t('price.fluency.optionsix')}
                    </span>
                </p>

                <p className="font-semibold  text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                        <span className="text-black">{i18n.t('price.fluency.optionseven')}</span></span>
                </p>

                {!('isRenderedInPayment' in props) &&
                <a href="https://speqy.lemonsqueezy.com/checkout/buy/a9b7ef05-696c-472c-ae60-8a35121fce3f" className="cursor-pointer w-full flex items-center justify-center">
                    <p className={`w-full flex items-center justify-center py-4 ${props.isRenderedInPayment ? 'uppercase animate-buy': 'bg-app/50'} mt-8 rounded-xl text-white`}>
                        <span className="font-medium">
                        {i18n.t('cta.two')}
                        </span>
                        <span className="pl-2 material-icons align-middle text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

                        </span>
                    </p>
                </a>}
            </div>
        </div>
}