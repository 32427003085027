/** 
 * This is the common reducer to handle with Network (fetch), messages and what ever
 * @type function 
 */

import { createReducer } from "@reduxjs/toolkit";
import * as action from './actions';
import {VIEW} from './constants';

const appFetchState = {
	isFetching: false,
	error: false,
	success: false,
	successMessage: "",
	errorMessage: "",
	cookie: null,
};

const appViewState = {
	CURRENT_UI: VIEW.JOINUS_APP
}

const formState = {
	isInvalidPassword:false,
	isInvalidEmail: false,
};

export const appViewReducers = createReducer(appViewState, (builder) =>{
	builder.addCase(action.showUI, (state, action) => {
		state.CURRENT_UI = action.payload.ui;
	} )
});
export const fetchReducer = createReducer(appFetchState, (builder) =>{
	builder.addCase(action.showRequestUI, (state, action) =>{
		state.isFetching = action.payload;
		state.error = false;

	}).addCase(action.showRequestError, (state, action) => {
		state.isFetching = false;
		state.error = true;
	}).addCase(action.showErrorMessage, (state, action) => {
		state.error = true;
		state.errorMessage = action.payload
	}).addCase(action.showSuccessMessage, (state, action) => {
		state.success = true;
		state.successMessage = action.payload
	}).addCase(action.hideMessage, (state, action) => {
		state.error = false;
		state.success = false;
	});
});

