
export const VIEW = {
    JOINUS:'SHOW_JOINUS',
    REQUEST_VERIFICATION_CODE_UI:'SHOW_REQUEST_VERIFICATION_CODE_UI',
    ENABLE_VERIFICATION_CODE: 'ENABLE_VERIFICATION_CODE',
    CONGRATULATIONS: 'CONGRATULATIONS',
    INCORRECT_CODE: 'INCORRECT_CODE',
    NOT_PHONE: 'NOT_PHONE'
}
export const USER = {
    SET_COUNTRY_CODE: 'SET_COUNTRY_CODE',
    SET_COUNTRY: 'SET_COUNTRY',
    SET_USER_PHONE: 'SET_USER_PHONE',
    VERIFY_CODE: 'SET_TOURS',
    SET_NAME: 'SET_NAME',
    SET_EMAIL: 'SET_EMAIL'
};

export const LESSON = {
    GET_LESSON:'GET_LESSON'
};