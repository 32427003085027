import Api from './services';
import { baseURL } from '../../common/constants';
import { createAction } from '@reduxjs/toolkit';
import { showErrorMessage, showRequestUI } from '../../common/actions';

export const displayLesson = createAction("DISPLAY_LESSON");

export const getLessons = () => async (dispatch, getState) => {
    dispatch(showRequestUI(true))
    try {

        const response = await Api.getLessons();
        if('lessons' in response.data){
            dispatch(displayLesson({data: response.data.lessons.splice(0,10)}))
        }
    } catch (error) {
        dispatch(showErrorMessage());
    }
    dispatch(showRequestUI(false))
};


export const setPlan = (id) => async (dispatch, getState) => {
    dispatch(showRequestUI(true))
    try {

        const response = await Api.setPlanUser(id);
        if('session' in response.data){
            window.location = `${baseURL}payment`;
        }
    } catch (error) {
        dispatch(showErrorMessage());
    }
    dispatch(showRequestUI(false))
};





