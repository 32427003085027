
import React, { useEffect, useState } from "react";


export default (props) => {
    const voice = {
        1: 'Samantha',
        4: 'Mónica',
        5: 'Luciana'

    }[props.code.id];
    const [display, setDisplay] = useState(false);
    const rounded = {
        1:'rounded-t-3xl rounded-l-3xl',
        2:'rounded-b-3xl rounded-l-3xl',
        3:'rounded-t-3xl rounded-l-3xl',
    }[props.type]
    const {timer, autoPlay} = props;
    const synth = window.speechSynthesis;

    useEffect(() =>{
         
        const timeout = setTimeout(()=>{
                const utterThis = new SpeechSynthesisUtterance(props.text);
                utterThis.rate = 0.8;
                if(voice){
                    const voices = synth.getVoices();
                    for(let i = 0; i < voices.length; i++){
                       if(voices[i].name == voice){
                            utterThis.voice = voices[i];
                            break;
                       }
                    }
                }
                synth.speak(utterThis);
            setDisplay(true);
        },500  * timer)
        return  () =>{
            setDisplay(false)
            clearInterval(timeout)
        };
    },[autoPlay]);

    return <>
        {display &&
        <div className={`opacity-80 md:opacity-100 font-bold shadow-md w-fit shadow-indigo-800 p-2 mb-2 bg-indigo-600 ${rounded} animate-messageenter`}>
            <span className="text-slate-50">{props.text}</span>
        </div>
        }
    </>
}