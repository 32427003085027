import React from "react";
import SectionContainer from "./sectionContainer";

export default function SectionTitle(props) {
  return (
    <div
      className="flex w-full px-8 flex-col  items-center">
      {props.pretitle && (
        <div className="text-sm font-bold tracking-wider text-indigo-600 uppercase">
          {props.pretitle}
        </div>
      )}

      {props.title && (
        <h2 className="max-w-2xl text-3xl font-bold leading-snug tracking-tight text-center text-gray-800 lg:leading-tight lg:text-4xl ">
          {props.title}
        </h2>
      )}

      {props.children && (
        <p className="max-w-3xl text-lg leading-normal text-gray-500  lg:text-lg xl:text-lg ">
          {props.children}
        </p>
      )}
    </div>
  );
}
