
import React, { useState,useCallback, useEffect } from "react";
import { dayFormatMMSS, hoursFormatMMSS, minutesFormatMMSS } from "../util/helpers";

export default (props) => {
    
    const data = localStorage ? localStorage.getItem('urgencytimer') ? localStorage.getItem('urgencytimer'): hoursFormatMMSS(props.maxHours) : hoursFormatMMSS(props.maxHours);
    const formatDDMMSS = props.maxHours ? data :'00:00:00';
    const [timer, setTimer] = useState(formatDDMMSS); 
    let interval =null;
    useEffect(() => {
                setTimer((t) => formatDDMMSS);
                interval = setInterval(()=>{
                   
                    if(props.maxHours){
                       
                        setTimer((t) =>{
                            
                            let [hours, minutes, seconds] = t.split(":");
                            hours = Number.parseInt(hours);
                            minutes = Number.parseInt(minutes);
                            seconds = Number.parseInt(seconds);
                            if(hours == 0 && minutes == 0 && seconds == 0){
                                clearInterval(interval);
                                props.onEndTimer && props.onEndTimer();
                                localStorage.setItem("urgencytimer", '00:00:00');
                                return '00:00:00';
                            }
                          
                           
                           
                            seconds = seconds == 0 ? 59 : seconds - 1;
                            minutes = seconds >= 59 ? minutes - 1 : minutes;
                            hours = minutes == 0 ? hours - 1: hours;
                            minutes = minutes== 0 ? 59:minutes;
                          
                            hours = minutes == 0 ? hours - 1: hours;
                            const strSeconds = seconds >= 10 ? seconds: '0'+seconds;
                            const strMinutes = minutes >= 10 ? minutes: '0'+minutes;
                            const strHours = hours >= 10 ? hours: '0'+hours;
                            
                            localStorage.setItem("urgencytimer", `${strHours}:${strMinutes}:${strSeconds}`);
                            props.onMinuteChangeCallbak && props.onMinuteChangeCallbak(minutes, seconds);
                            return `${strHours}:${strMinutes}:${strSeconds}`;
                        });
                    }else{
                        setTimer((t) =>{
                            
                            let [hours, minutes, seconds] = t.split(":");
                            if(hours == 0 && minutes == 0 && seconds == 0){
                                clearInterval(interval);
                                props.onEndTimer && props.onEndTimer();
                                return '00:00:00';
                            }
                            hours = Number.parseInt(hours);
                            minutes = Number.parseInt(minutes);
                            seconds = Number.parseInt(seconds);
                            seconds = seconds > 59 ? 0 : seconds + 1;
                            minutes = seconds > 59 ? minutes + 1 : minutes;
                            hours = minutes > 59 ? hours + 1: hours;
                            minutes = minutes== 59 ? 0:minutes;
                            const strSeconds = seconds >= 10 ? seconds: '0'+seconds;
                            const strMinutes = minutes >= 10 ? minutes: '0'+minutes;
                            const strHours = hours >= 10 ? hours: '0'+hours;
                            localStorage.setItem("urgencytimer", `${strHours}:${strMinutes}:${strSeconds}`);
                            props.onMinuteChangeCallbak && props.onMinuteChangeCallbak(minutes, seconds);
                            return `${strHours}:${strMinutes}:${strSeconds}`;
                        });
                    }
                }, 1000);
                return () => clearInterval(interval);
    
        }, [props, interval]);
    

    return <div className={`${props.class} mx-2 flex items-center justify-center text-center`}>
                <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg></span>

            <span className={`${props.class}`}>{timer}</span>
        </div>
}