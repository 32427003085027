import Server from "../../common/auth"



export default {
    verifyAuthenticationCode(code, countryCode, number){
        let form = new FormData();
        form.append('code', code);
        form.append('number', number);
        form.append('country_code', countryCode);
        return Server.post('verify-code')(form);
    },
    getLessons(){
        return Server.get('get-lessons');
    },
    startConversation(data){
        const form = new FormData();
        form.append('phone_number', data.phoneNumber);
        form.append('country', data.country);
        form.append('country_code', data.countryCode);
        if(!data.email === null )
            form.append('email', data.email);

        if (!data.name === null)
            form.append('name', data.name);
        
        return Server.post('start-chat')(form);
    },
    getVerificationCode(countryCode, number){
        let form = new FormData();
        form.append('number', number);
        form.append('country_code', countryCode);
        return Server.post('join-code')(form);
    },
}