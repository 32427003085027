import React from 'react';
import {connect} from 'react-redux';
import { FETCHING, VIEW } from './constants';
import NameInput from './nameInput'
import { getLang } from './actions';
import LearningLanguage from './selectlang';
import i18n from '../util/i18n';
class Conversation extends React.Component{
    constructor(props){
        super(props)
    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(getLang())
    }
   
    render(){
        
        const { CURRENT_UI, isFetching, NOT_NAME, error, langs, INCORRECT_CODE, dispatch} = this.props;
        const disabledResendCodeButton = isFetching ? 'disabled:bg-gray-500':'';
        const animated = INCORRECT_CODE ? 'animate-wiggle':''; 
        return(
            <div className='my-4 w-full md:flex md:justify-center'> 
            {/*<div className="text-sm text-center text-gray-700 dark:text-white">
                <span className='text-3xl font-bold text-transparent bg-gradient-to-r bg-clip-text   from-[#f79533] via-[#f37055] to-[#ef4e7b] '> {i18n.t('headline')}</span>
        </div>*/}
             {error && <p className='text-indigo-700 font-bold'>🤖 {i18n.t('server.error')}</p>}
               
                    {CURRENT_UI == VIEW.JOINUS && <LearningLanguage langs={langs} isFetching={isFetching == FETCHING.INITIALIZING_CHAT} />}
                {CURRENT_UI == VIEW.TEST_FINISHED && 
                    <div className="justify-center w-full ml-2 flex flex-wrap flex-col sm:flex-row items-center content-center">
                        <p className='text-lg'><a href='/chat' className='border-b border-gray-700'>{i18n.t('cta.one')}</a></p>
                    </div>
                }
            
            </div>
        )
    }
}

const mapStateToProps = (state)=> {
    const { CURRENT_UI, NOT_NAME } = state.conversationViewReducers;
    const {isFetching, error} = state.fetchReducer;
    const {langs} = state.conversationReducer;
    
    return {
        CURRENT_UI,
        NOT_NAME,
        isFetching,
        error,
        langs
    }
}

export default connect(mapStateToProps)(Conversation);