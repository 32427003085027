
import Server from "../../common/auth"



export default {
    startConversation(data){
        const form = new FormData();
        form.append('lang', data);
        return Server.post('set-user-chat')(form);
    },
    getLang(){
        return Server.get('get-lang');
    },
    setlang(lang){
        const form = new FormData()
        form.append('lang_id', lang.id)
        return Server.post('lets-chat')(form);
    }
    
}