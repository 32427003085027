import React from 'react';
import {connect} from 'react-redux';
import { VIEW } from './constants';
import NumberInput from './numberInput'
import ReactCodeInput from 'react-code-input';
import { verifyAuthenticationCode, handleVerificationCode } from './actions';
class JoinUs extends React.Component{
    constructor(props){
        super(props)
    }
    onHandleChangeCode = (e) => {
       const {dispatch} = this.props;
        dispatch(verifyAuthenticationCode(e));
    }
    render(){
        const { CURRENT_UI, isFetching, NOT_PHONE, error, INCORRECT_CODE, dispatch} = this.props;
        const disabledResendCodeButton = isFetching ? 'disabled:bg-gray-500':'';
        const animated = INCORRECT_CODE ? 'animate-wiggle':'';
        return(
            <>  {error && <p className='text-indigo-700 font-bold'>🤖 Agradecemos su interés en conversar con nosotros,  <br /> Por favor, tenga paciencia y vuelva a intentarlo más tarde. Estamos comprometidos en brindarle la mejor atención posible.</p>}
                {CURRENT_UI == VIEW.JOINUS && <NumberInput notPhoneNumber={NOT_PHONE} isFetching={isFetching}/>}
                {CURRENT_UI == VIEW.ENABLE_VERIFICATION_CODE && 
                <div className={`justify-center z-1 w-full flex flex-col sm:flex-row flex-wrap items-center content-center ${animated}`}>
                    <h1>Confirmar código</h1>
                    <ReactCodeInput type='text' fields={6} onChange={this.onHandleChangeCode} /> 
                    <button type="button"
                        onClick={(e)=> {dispatch(handleVerificationCode())}}
                            className={`px-8 py-4 leading-4 sm:py-1  shadow-lg shadow-indigo-200 sm:w-40 text-base rounded-full font-medium text-center text-white  bg-indigo-600 ${disabledResendCodeButton}`} disabled={isFetching}>
                            {isFetching ? 
                            <>
                            <span className='relative text-center justify-center'>
                            <svg className="animate-spin h-5 w-5 m-auto  text-white" viewBox="0 0 24 24" fill='none'>
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            </span>
                            <span className='text-xs text-center'>Verificando</span>
                            </> : 'Reenviar código'}
                        </button>
                </div>}
                {CURRENT_UI == VIEW.CONGRATULATIONS && 
                    <div className="justify-center w-full flex flex-wrap flex-col sm:flex-row items-center content-center">
                        <h5 className='text-2xl font-bold text-indigo-500'> 🎉 Felicitaciones</h5>
                        <p className='text-lg'>Revisa tu Whatsapp, comienza la coversación con tu primera lección</p>
                    </div>
                }
            <div className="text-sm text-justify text-gray-700 dark:text-white">
            <p>Al hacer clic en <span className='text-indigo-500'>comenzar conversación</span> aceptar los <a href='/terminos-y-condiciones' className='text-blue-700'>Términos y condiciones de uso</a> </p>
            <p>Te enviaremos un código a Whatsapp, vale!</p>
          </div>
            </>
        )
    }
}

const mapStateToProps = (state)=> {
    const { CURRENT_UI, NOT_PHONE, INCORRECT_CODE} = state.joinViewReducers;
    const {isFetching, error} = state.fetchReducer;
    return {
        CURRENT_UI,
        NOT_PHONE,
        isFetching,
        INCORRECT_CODE,
        error
    }
}

export default connect(mapStateToProps)(JoinUs);