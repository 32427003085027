
import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { COUNTRIES } from '../../common/constants'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useDispatch, useStore } from 'react-redux';
import {setPhoneNumber, handleVerificationCode, setCountry} from './actions';
import { cookies } from 'next/dist/client/components/headers';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default (props)=> {
  const [selected, setSelected] = useState(COUNTRIES[0])
  const dispatch = useDispatch();
  const store  = useStore();
  const animated = props.notPhoneNumber > 0 ? 'animate-wiggle':'';
  const colorPlaceholder = props.notPhoneNumber == 0 ? 'placeholder:text-gray-400':'placeholder:text-indigo-400';

  const handleSelectedCountry = (data) => {
    setSelected(data);
    dispatch(setCountry({
      country: data.title,
      countryCode: data.value
    }));
  }
  return (
    <div className={`${animated} relative items-center justify-center w-full sm:justify-start content-center  rounded-full border-0 border-solid flex-wrap border-gray-300 sm:w-fit flex flex-col ring-1 ring-inset  ring-gray-300 bg-white sm:flex-row`}>
   <Listbox value={selected} onChange={handleSelectedCountry}>
      {({ open }) => (
        <>
          <div className="relative h-100">
            <Listbox.Button className="relative items-center sm:w-44 w-fit cursor-default mt-3 sm:mt-0 ml-3.5 bg-white py-1.5 sm:py-0 pr-10 text-left text-gray-900  sm:text-sm sm:leading-6">
              <span className="flex items-center content-center">
                <span className="ml-3 block text-2xl sm:text-lg truncate">{selected.title}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-70 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5  sm:text-sm">
                {COUNTRIES.map(( country, index) => (
                    
                  <Listbox.Option
                    key={index}
                    className='text-gray-900
                        relative cursor-default select-none py-2 pl-3 pr-9'
                    value={country}
                  >
                        <div className="flex items-center">
                        <span className={`fi fi-${country.value}`}></span>
                          <span
                            className='font-semibold  ml-3 block truncate'
                          >
                            {`${country.title} (${country.ncode})`}
                          </span>
                        </div>

                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
      <div className="relative my-1 ml self-middle align-middle">
        <input
          type="tel"
          name="phone_number"
          id="phone_number"
          className={`block text-2xl sm:text-base sm:text-center ml-2 sm:ml-0 w-4/5 sm:w-auto outline-none border-0  focus:ring-0 text-gray-900 ${colorPlaceholder}`}
          placeholder="Número de Whatsapp"
          onChange={(e) =>{
            dispatch(setPhoneNumber(e.target.value))
          }}
        />
        </div>
        <button type="button"
               onClick={(e)=> {dispatch(handleVerificationCode())}}
                className="px-8 py-4 leading-4 sm:py-1  shadow-lg shadow-indigo-200 sm:w-40 text-base rounded-full font-medium text-center text-white bg-[#3B3486]" disabled={props.isFetching}>
                {props.isFetching ? 
                <>
                <span className='relative text-center justify-center'>
                  <svg className="animate-spin h-5 w-5 m-auto  text-white" viewBox="0 0 24 24" fill='none'>
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
                  <span className='text-xs text-center'>Enviando</span>
                </> : 'Comenzar conversación'}
              </button>
    </div>
  )
}