/**
 * Common actions which do a little bit change in the app
 * the only way for interacting with the app by shifting the state
 */

import { createAction } from "@reduxjs/toolkit";
import { NETWORK, VIEW, FORM, RESPONSE } from "./constants";

//var { validateEmail } = require("../helper/validateForm");


export const showRequestUI = createAction(NETWORK.FETCH_REQUEST);
export const showRequestError = createAction(NETWORK.FETCH_ERROR);
export const responseState = createAction(RESPONSE.STATE);
export const updateCookie = createAction(RESPONSE.COOKIE);
/**
 * set the error messages to display in the view component
 * @param {string} message 		it is a error, info or warning
 */

export const showErrorMessage = createAction(VIEW.SET_ERROR_MESSAGE);
export const hideMessage = createAction(VIEW.HIDE_ALERT_MESSAGE);
export const showSuccessMessage = createAction(VIEW.SET_SUCCESS_MESSAGE);
export const showUI = createAction(VIEW.SHOW_UI);
/**
 * @param  {boolean} isValidEmail  the new state to verify if email is valid for sending the data
 * @return {object}       			formatted action data for the reducer of redux
 */

export const matchEmail = (email) => {
	let validate = false;
	if(!validateEmail(email) )
		validate = true

	return isNotValidEmail(validate);
}; 


export const isNotValidEmail = createAction(FORM.EMAIL_NOT_MATCH);

export const isNotValidPassword = createAction(FORM.PASSWORD_NOT_MATCH);