import React from 'react';
import {createRoot} from 'react-dom/client'; 
import Home from './components/home/index';
import { Provider } from 'react-redux';
import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit';
import { fetchReducer } from './common/reducers';
import { joinViewReducers, userReducer, lessonReducer} from './components/app/reducers';
import { conversationViewReducers, conversationReducer } from './components/conversation/reducers';
//import { BrowserRouter } from "react-router-dom";
const root = createRoot(document.getElementById('app'));
const store = configureStore({
    reducer: {fetchReducer, joinViewReducers, userReducer, lessonReducer, conversationReducer, conversationViewReducers },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(),
});

root.render(
    <Provider store={store}>
        <Home />
    </Provider>
);