import React from "react";
import SectionFooter from '../util/sectionFooter';
import SectionTitle from "../util/sectionTitle";
import SectionHero from '../util/sectionHero';
import SectionNav from '../util/sectionNav';
import SectionContainer from '../util/sectionContainer';
import { Disclosure } from '@headlessui/react';               
import Price from "./price";
import LessonCard from "./lessonCard";
import {connect} from 'react-redux';
import { getLessons } from "./actions";
import Timer from "./timer";
import i18n from "../util/i18n";
import Faq from "./faq";

class Home extends React.Component {
    constructor(props){
        super(props)

    }

    componentDidMount(){
      //  const { dispatch } = this.props;
       // dispatch(getLessons());
     }

    render(){
        const { lessons , isFetching} = this.props;
       {/* const displayLessons =  lessons.map((lesson, index) =>{
            return <LessonCard key={index} lesson={lesson}/>
        });*/}
        
        return (
            <>
            <SectionNav />
            <SectionHero />
          
                <div className="container relative  mx-auto xl:px-0 flex justify-center ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12  text-app">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
                </svg>
                </div>
            
            <SectionContainer>
            <div id={i18n.t('menu.id.howitworks')} />
            <SectionTitle
              pretitle={i18n.t('howitworks.title')}
              title={i18n.t('howitworks.subtitle')}>
                <div  className="text-base flex  justify-center text-justify text-gray-700 my-2">
                {i18n.t('howitworks.text')}
                </div>
                <div className="flex flex-col md:flex-row w-full items-baseline justify-normal gap-3">

                <div className=" w-full md:w-2/6 mx-2 flex flex-col justify-center items-center text-center">
                    <span className="p-1 w-10 h-10 my-2 rounded-full bg-indigo-600 text-white font-bold text-2xl align-top">1</span>
                    <span className="text-xl text-indigo-600 font-bold">{i18n.t('howitworks.stepone')}</span>
                    <img src="static/img/step1.png" />
                </div>

                <div className="self-center transform rotate-90 md:rotate-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

                </div>
                <div  className=" w-full md:w-2/6 mx-2 flex flex-col justify-center items-center text-center">
                    <span className="p-1 w-10 h-10 my-2 rounded-full bg-indigo-600 text-white font-bold text-2xl align-top">2</span>
                    <span className="text-lg text-indigo-600 font-bold">{i18n.t('howitworks.steptwo')}</span>
                    <img src="static/img/step2.png" />
                    </div>  
                    <div className="self-center transform rotate-90 md:rotate-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

                </div>  
                <div  className=" w-full md:w-2/6 mx-2 flex flex-col justify-center items-center text-center">
                    <span className="p-1 w-10 h-10 my-2 rounded-full bg-indigo-600 text-white font-bold text-2xl align-top">3</span>
                    <span className="text-lg text-indigo-600 font-bold">{i18n.t('howitworks.stepone')}</span>
                    <img src="static/img/step3.png" />
                    </div>    
               </div>
            </SectionTitle>
            </SectionContainer>

        
        
            <SectionContainer className="flex flex-wrap justify-center px-8">
                <div id={i18n.t('menu.id.usecases')}></div>
            <SectionTitle
              pretitle={i18n.t('usecases.title')}
              title={i18n.t('usecases.subtitle')}>
                <span >{i18n.t('usecases.text')}</span>
            </SectionTitle>
                    <LessonCard lesson={{title:i18n.t('usecase.one.title'),description:i18n.t('usecase.one.description')}}/>
                    <LessonCard lesson={{title:i18n.t('usecase.two.title'),description:i18n.t('usecase.two.description')}}/>
                    <LessonCard lesson={{title:i18n.t('usecase.three.title'),description:i18n.t('usecase.three.description')}}/>
                    <LessonCard lesson={{title:i18n.t('usecase.four.title'),description:i18n.t('usecase.four.description')}}/>
                    <LessonCard lesson={{title:i18n.t('usecase.five.title'),description:i18n.t('usecase.four.description')}}/>
                    <LessonCard lesson={{title:i18n.t('usecase.six.title'),description:i18n.t('usecase.six.description')}}/>
                  
            </SectionContainer>
            <SectionContainer className="flex flex-col items-center justify-center">
                <div id={i18n.t('menu.id.price')}></div>
                <div className="blur-[106px] inset-x-0 top-1/3 absolute  h-40 bg-gradient-to-br from-cyan-400 to-purple-400 "></div>
                <div className="blur-[106px] inset-x-0 top-full   absolute h-15 bg-gradient-to-r from-cyan-400 to-sky-300"></div>
               
                <SectionTitle
                pretitle={i18n.t('price.title')}
                title={i18n.t('price.subtitle')}>
                   <span className="text-indigo-800 font-bold"> {i18n.t('price.text')}</span>
                </SectionTitle>
                <Timer maxHours={24} class="text-3xl text-red-600 font-bold" />
                <Price />
            </SectionContainer>
            <SectionContainer className="flex flex-col justify-center">
                    <div id={i18n.t('menu.id.faq')} />
                    <SectionTitle
                        pretitle={i18n.t('menu.faq')}
                        title={i18n.t('menu.faq')}>
                    </SectionTitle>
                    <Faq />
            </SectionContainer>
            <SectionFooter />
            
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {lessons} = state.lessonReducer;
    const { isFetching } = state.fetchReducer;
    return {lessons,isFetching};
}; 

export default connect(mapStateToProps)(Home);