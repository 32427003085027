
import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {ChevronUpDownIcon } from '@heroicons/react/20/solid'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useDispatch, useStore } from 'react-redux';
import { letsChat } from './actions';
import Message from './message';
import i18n from '../util/i18n';

export default   (props)=> {

  const [selectedLearning, setLearningLang] = useState(props.langs[0])
  const [audioOn, setAudioOn] = useState(false);
  const dispatch = useDispatch();
  const store  = useStore();
  const animated = props.notPhoneNumber > 0 ? 'animate-wiggle':'';
  const colorPlaceholder = props.notPhoneNumber == 0 ? 'placeholder:text-gray-400':'placeholder:text-indigo-400';
 
  const handleLearningLang = () => {
    dispatch(letsChat(selectedLearning));
  }
  return (
    <div className={`${animated} bg-app rounded-xl md:rounded-none md:bg-transparent p-2 relative items-center justify-center w-full content-center  flex-wrap sm:w-fit flex flex-col   sm:flex-row my-10 md:ml-24`}>
      <div className='absolute bottom-full md:right-full md:top-0 flex flex-col w-full items-end'>
        {selectedLearning.id == 1 && <>
        <Message text={"What's up?"} type={1} timer={0} code={selectedLearning} autoPlay={audioOn}/>
        <Message text={"How's it going?"} type={2} timer={0.8} code={selectedLearning} autoPlay={audioOn}/>
        <Message text={"Have any exciting plans for the weekend?"} type={2} timer={5} code={selectedLearning} autoPlay={audioOn}/>
        </>}

        {selectedLearning.id == 4 && <>
        <Message text={"¿Como estás?"} type={1} timer={0} code={selectedLearning} autoPlay={audioOn}/>
        <Message text={"¿Cómo te va?"} type={2} timer={0.8} code={selectedLearning} autoPlay={audioOn}/>
        <Message text={"¿Tienes planes emocionantes para el fin de semana?"} type={2} timer={5} code={selectedLearning} autoPlay={audioOn}/>
        </>}

        {selectedLearning.id == 5 && <>
        <Message text={"Olá, tudo bem ?"} type={1} timer={0} code={selectedLearning} autoPlay={audioOn}/>
        <Message text={"E aí, como vão as coisas?"} type={2} timer={0.8} code={selectedLearning} autoPlay={audioOn}/>
        <Message text={"Tem algum plano massa para o final de semana?"} type={2} timer={5} code={selectedLearning} autoPlay={audioOn}/>
        </>}

      </div>
      <div className='m-2 cursor-pointer animate-zoomIn' onClick={()=>setAudioOn(true)}>
      {audioOn ?
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 md:text-gray-800 text-white">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
</svg> : 

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 md:text-gray-800 text-white">
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" />
    </svg>}

      </div>
      <Listbox value={selectedLearning} onChange={setLearningLang}>
      {({ open }) => (
        <>
          <div className="w-full md:w-auto relative md:h-100 ring-4 ring-app-200 md:rounded-full md:p-7 md:bg-app md:border-l z-30 md:z-0 justify-center">
            <Listbox.Button className="md:bg-app flex justify-center relative items-center sm:w-full  cursor-default  mx-auto md:ml-3.5  py-5 sm:py-0 pr-10 text-center md:text-left text-gray-900 ">
              <span className="flex items-center content-center">
                <span className="ml-3 block text-2xl truncate text-white">{selectedLearning.description}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            > 
              <Listbox.Options className="absolute z-10 mt-1 max-h-70 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5  sm:text-sm">
                {props.langs.map((lang, index) => (
                    
                  <Listbox.Option
                    key={index}
                    className='text-gray-900
                        relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-app-200'
                    value={lang}
                  >
                        <div className="flex items-center ">
                          <span
                            className='font-semibold  ml-3 block truncate text-xl' 
                          >
                            {lang.description}
                          </span>
                        </div>

                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
   
    <button 
    onClick={!props.isFetching ?  handleLearningLang:()=>{}}
          className={`relative z-10 ${props.isFetching ? 'animate-buy':'animate-micscale'} md:-ml-10 p-4 leading-4 shadow-xl shadow-indigo-200 sm:w-fit text-base rounded-full font-medium text-center text-white bg-app active:scale-95 active:ring active:ring-app-200`}
       type='button'>
        {props.isFetching && <div className='animate-loadingchat absolute w-full h-full border-4 border-white border-dashed rounded-full top-0 left-0'> </div>}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-20 h-20">
            <path d="M8.25 4.5a3.75 3.75 0 117.5 0v8.25a3.75 3.75 0 11-7.5 0V4.5z" />
            <path d="M6 10.5a.75.75 0 01.75.75v1.5a5.25 5.25 0 1010.5 0v-1.5a.75.75 0 011.5 0v1.5a6.751 6.751 0 01-6 6.709v2.291h3a.75.75 0 010 1.5h-7.5a.75.75 0 010-1.5h3v-2.291a6.751 6.751 0 01-6-6.709v-1.5A.75.75 0 016 10.5z" />
          </svg>
       </button>

        <button type="button"
               onClick={handleLearningLang}
                className=" -ml-5 px-4 py-8 leading-6 sm:py-1  sm:w-40 text-2xl rounded-r-full font-medium text-center text-white bg-[#3B3486]" disabled={props.isFetching}>
                {props.isFetching ? 
                <>
                <span className='relative text-center justify-center'>
                  <svg className="animate-spin h-5 w-5 m-auto  text-white" viewBox="0 0 24 24" fill='none'>
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
                < span className='text-xs text-center'>{i18n.t('ai.initializing')}</span>
                </> : "Let's chat"}
              </button>
    </div>
  )
}