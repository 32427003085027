import React from 'react';

export const VIEW = {
    DISPLAY_PAYMENT: 'DISPLAY_PAYMENT',
    DISPLAY_JOINUS: 'DISPLAY_JOINUS',
    DISPLAY_CONGRATULATIONS: 'DISPLAY_CONGRATULATIONS',
    PAYMENT_UI: 'PAYMENT_UI',
    DISPLAY_PAYMENT_ERROR: 'DISPLAY_PAYMENT_ERROR',
    DISPLAY_PRICES: 'DISPLAY_PRICES',
    DISPLAY_ENABLE_LESSON:'DISPLAY_ENABLE_LESSON'
}
export const USER = {
    SET_PLAN: 'SET_PLAN',
    SET:'SET'
};

