import Api from './services';
import {showRequestUI, showRequestError, updateCookie, showUI, showErrorMessage, showSuccessMessage} from '../../common/actions';
import { createAction } from '@reduxjs/toolkit';
import { USER, VIEW} from './constants';
import JSConfetti from 'js-confetti';

export const setName = createAction(USER.SET_NAME);
export const setEmail = createAction(USER.SET_EMAIL);
export const setCountryCode = createAction(USER.SET_COUNTRY_CODE);
export const setCountry = createAction(USER.SET_COUNTRY)
export const setPhoneNumber = createAction(USER.SET_USER_PHONE);
export const enableSendVerificationCode = createAction(VIEW.ENABLE_VERIFICATION_CODE);
export const resendVerificationCode = createAction();
export const displayIncorrectCodeUI = createAction(VIEW.INCORRECT_CODE);
export const wiggleNotPhone = createAction(VIEW.NOT_PHONE);
export const getLessons = () => async (dispatch, getState) => {
    Api.getLessons(data);
};


export const startConversation  = () => async (dispatch, getState) => {
    try {
        
        dispatch(showRequestUI(true));
        const response = await Api.startConversation({...getState().userReducer});
        if (response.data.started){
            dispatch(showUI({ui:VIEW.CONGRATULATIONS}))
            const jsConfetti = new JSConfetti();
                jsConfetti.addConfetti({
                    emojis: ['🔥', '🥳', '💥', '🎉', '🟪', '🧸','🟡','🔵'],
                });
        }else{
            dispatch(showErrorMessage(response.data.error));
        }
        dispatch(showRequestUI(false));
    } catch (error) {
        dispatch(showRequestUI(false));
        dispatch(showErrorMessage())
    }

};


export const handleVerificationCode = () => async (dispatch, getState) => {
    
    try {
        
        const { phoneNumber, countryCode } = getState().userReducer;
        if(phoneNumber.length < 8){
            dispatch(wiggleNotPhone(1));
            return;
        }
        dispatch(showRequestUI(true))
        if (phoneNumber != null){ 
            let response = await Api.getVerificationCode(countryCode, phoneNumber);
            if ('code' in response.data)
                dispatch(showUI({ui:VIEW.ENABLE_VERIFICATION_CODE}))
            else
                dispatch(showErrorMessage())
        }else{
            dispatch(showErrorMessage())
        }
        dispatch(showRequestUI(false));
    } catch (error) {
    
       dispatch(showRequestError())
    }
};


export const verifyAuthenticationCode = (code) => async (dispatch, getState) => {
    if (code.length <=5 ) return; 
        const { phoneNumber, countryCode } = getState().userReducer;
        dispatch(showRequestUI(true));
        try {
            let response = await Api.verifyAuthenticationCode(code, countryCode, phoneNumber);
            
            if(response.data.is_equal){
               dispatch(startConversation())
            }else{
                dispatch(displayIncorrectCodeUI(true));
                setTimeout(()=> dispatch(displayIncorrectCodeUI(false)),500);
            }
            dispatch(showRequestUI(false));
        } catch (error) {
            dispatch(showRequestError())
        }
   
};