import React from 'react';
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import i18n from '../util/i18n';

export default (props) =>{
    
    return (<div className='flex flex-col items-center  w-full relative gap-3 px-8 md:px-0'>
        <Disclosure className=" justify-center p-4 shadow-md">
        {({ open }) => (
            <div className='py-4 rounded-lg shadow-md shadow-app-200 border flex flex-col items-center justify-evenly w-full md:w-6/12'>
                <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                <span className='text-app font-bold'>{i18n.t('faq.zero.title')}</span>
                <ChevronRightIcon className={`w-8 h-8 ${open ? '-rotate-90 transform' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                {i18n.t('faq.zero.description')}
                </Disclosure.Panel>
            </div>
        )}    
        </Disclosure>

        <Disclosure className=" justify-center p-4 shadow-md">
        {({ open }) => (
            <div className='py-4 rounded-lg shadow-md shadow-app-200 border flex flex-col items-center justify-evenly w-full md:w-6/12'>
                <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                <span className='text-app font-bold'>{i18n.t('faq.one.title')}</span>
                <ChevronRightIcon className={`w-8 h-8 ${open ? '-rotate-90 transform' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                {i18n.t('faq.one.description')}
                </Disclosure.Panel>
            </div>
        )}    
        </Disclosure>

        <Disclosure className=" justify-center  p-4 shadow-md">
        {({ open }) => (
            <div className='py-4 rounded-lg shadow-md shadow-app-200 border flex flex-col items-center justify-evenly w-full md:w-6/12'>
                <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                <span className='text-app font-bold'>{i18n.t('faq.two.title')}</span>
                <ChevronRightIcon className={`w-8 h-8 ${open ? '-rotate-90 transform' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                {i18n.t('faq.two.description')}
                </Disclosure.Panel>
            </div>
        )}    
        </Disclosure>

        <Disclosure className=" justify-center p-4 shadow-md">
        {({ open }) => (
            <div className='py-4 rounded-lg shadow-md shadow-app-200 border flex flex-col items-center justify-evenly w-full md:w-6/12'>
                <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                <span className='text-app font-bold'>{i18n.t('faq.three.title')}</span>
                <ChevronRightIcon className={`w-8 h-8 ${open ? '-rotate-90 transform' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                {i18n.t('faq.three.description')}
                </Disclosure.Panel>
            </div>
        )}    
        </Disclosure>
        
        <Disclosure className=" justify-center p-4 shadow-md">
        {({ open }) => (
            <div className='py-4 rounded-lg shadow-md shadow-app-200 border flex flex-col items-center justify-evenly w-full md:w-6/12'>
                <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                <span className='text-app font-bold'>{i18n.t('faq.four.title')}</span>
                <ChevronRightIcon className={`w-8 h-8 ${open ? '-rotate-90 transform' : ''}`} />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                {i18n.t('faq.four.description')}
                </Disclosure.Panel>
            </div>
        )}    
        </Disclosure>
        </div>
    );
}