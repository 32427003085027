import React from "react";
import {useDispatch} from 'react-redux';
import { setPlanUser } from "./actions";
import SectionContainer from '../util/sectionContainer';
import SectionTitle from '../util/sectionTitle';
import i18n from '../util/i18n';

export default (props) => {
    const dispatch = useDispatch();
    const onHandelSelectedPlan  = (planId) => (evt) => {
        dispatch(setPlanUser(planId));
        evt.preventDefault();
        evt.stopPropagation();
    }
    return <div className="w-80 p-8 bg-indigo-800 text-center mt-9 rounded-3xl text-white border-4 shadow-xl border-white transform scale-125">
            <h1 className="text-white font-semibold text-2xl">{i18n.t('price.basic.title')}</h1>
            <p className="pt-2 tracking-wide flex items-center justify-center">
                
                <span className="text-red-600 text-3xl line-through">$15</span>
                <span className="pl-2 material-icons align-middle text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

                </span>
                <span className="text-gray-400 align-top">$ </span>
                <span className="text-3xl font-semibold">9</span>
                <span className="text-gray-400 font-medium">/{i18n.t('user')}</span>
            </p>
            <hr className="mt-4 border-1 border-gray-600" />
            <div className="pt-8">
               {/*} <p className="font-semibold text-gray-400 text-left">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>

                    </span>
                    <span className="pl-2">
                        Conversación de <span className="text-white">20 minutos</span>
                    </span>
    </p>*/}
                <p className="font-semibold text-gray-400 text-left flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                        <span className="text-white">{i18n.t('price.basic.optionone')}</span>
                    </span>
                </p>
                <p className="font-semibold text-gray-400 text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                    <span className="text-white">{i18n.t('price.basic.optiontwo')}</span>
                    </span>
                </p>
                <p className="font-semibold text-gray-400 text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                    <span className="text-white">{i18n.t('price.basic.optionthree')}</span>
                    </span>
                </p>
                <p className="font-semibold text-gray-400 text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                    {i18n.t('price.basic.optionfour')}
                    </span>
                </p>

                <p className="font-semibold text-gray-400 text-left pt-1 flex items-center justify-start">
                    <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
                    <span className="pl-2">
                    {i18n.t('price.basic.optionseven')}
                    </span>
                </p>
                {!('isRenderedInPayment' in props) &&
                <a href="https://speqy.lemonsqueezy.com/checkout/buy/9bb7901e-9739-46e4-98a8-4a769a7d8f63" className="w-full cursor-pointer px-2">
                    <p className="w-full py-4 px-4 animate-buy mt-8 rounded-xl text-white flex items-center justify-center">
                        <span className="font-medium  uppercase">
                        {i18n.t('cta.two')}
                        </span>
                        <span className="pl-2 material-icons align-middle text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 font-bold">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

                        </span>
                    </p>
                </a>}
            </div>
            {/*<div className="absolute top-4 right-4">
                <p className="bg-blue-700 font-semibold px-4 py-1 rounded-full uppercase text-xs">Best for you</p>
</div>*/}
        </div>
}