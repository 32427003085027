import React from "react";
import {useDispatch} from 'react-redux';
import { setPlanUser } from "./actions";
import i18n from '../util/i18n';
export default (props) => {
    const dispatch = useDispatch();
    const onHandelSelectedPlan  = (planId) => (evt) => {
        dispatch(setPlanUser(planId));
        evt.preventDefault();
        evt.stopPropagation();
    }
    return (<div className="w-96 p-8 bg-white text-center rounded-3xl shadow-xl">
    <h1 className="text-black font-semibold text-2xl">{i18n.t('price.free.title')}</h1>
    <p className="pt-2 tracking-wide flex items-center justify-center">
        {/*<span className="text-red-600 text-4xl line-through pr-2 ">$5</span>
        <span className="pl-2 material-icons align-middle text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

                </span>*/}
        <span className="text-gray-400 align-top pl-2">$ </span>
       
        <span className="text-3xl font-semibold"> {i18n.t('price.free.title')}</span>
        <span className="text-gray-400 font-medium">/ {i18n.t('user')}</span>
    </p>
    <hr className="mt-4 border-1" />
    <div className="pt-8">
        <p className="font-semibold  text-left flex items-center justify-start">
        <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
            <span className="pl-2">
             {i18n.t('price.free.optionone')}
            </span>
        </p>
        <p className="font-semibold  text-left pt-5 flex items-center justify-start">
        <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
            <span className="pl-2">
            {i18n.t('price.free.optiontwo')}
            </span>
        </p>
        <p className="font-semibold  text-left pt-5 flex items-center justify-start">
        <span className="material-icons align-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
                    </span>
            <span className="pl-2">
                     {i18n.t('price.free.optionthree')}
            </span>
        </p>     
            
            <a href="/chat" className="cursor-pointer w-full">
                <p className="w-full py-4 bg-app/50 mt-8 rounded-xl text-white flex items-center justify-center">
                    <span className="font-medium">
                            {i18n.t('cta.three')}
                    </span>
                   
                        <span className="pl-2 material-icons align-middle text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

                        </span>

                </p>
            </a>
      
    </div>
</div>);
}