import React from "react";
import {useDispatch} from 'react-redux';
import { setPlan } from "./actions";
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import PriceFree from "../payment/price.free";
import PriceBasic from "../payment/price.basic";
import PriceFluency from "../payment/price.fluency";

export default (props) => {
    const dispatch = useDispatch();
    const onHandelSelectedPlan  = (planId) => (evt) => {
        dispatch(setPlan(planId));
        evt.preventDefault();
        evt.stopPropagation();
    }
    return (
        <div id="price" className="z-10 flex flex-row flex-wrap items-center justify-center px-8">
            <PriceFree />
            <PriceBasic />
            <PriceFluency />
        </div>
    )
}