import Api from './services';
import {showRequestUI, showRequestError, updateCookie, showUI, showErrorMessage, showSuccessMessage} from '../../common/actions';
import { createAction } from '@reduxjs/toolkit';
import { FETCHING, USER, VIEW} from './constants';
import JSConfetti from 'js-confetti';
import { baseURL } from '../../common/constants';

export const setName = createAction(USER.SET_NAME);
export const displayLangs = createAction(USER.SET_LANG);
export const wiggleNotName= createAction(VIEW.NOT_NAME);

export const startConversation  = () => async (dispatch, getState) => {
    const { name } = getState().conversationReducer;
    if(name.length < 3){
        dispatch(wiggleNotName(1));
        return;
    }
    try {
        
        dispatch(showRequestUI(true));
        const response = await Api.startConversation(name);
        if (response.data.started){
            dispatch(showUI({ui:VIEW.CONGRATULATIONS}))
            const jsConfetti = new JSConfetti();
                jsConfetti.addConfetti({
                    emojis: ['🔥', '🥳', '💥', '🎉', '🟪', '🧸','🟡','🔵'],
                });
        }else{
            dispatch(showErrorMessage(response.data.error));
        }
        dispatch(showRequestUI(false));
    } catch (error) {
        dispatch(showRequestUI(false));
        dispatch(showErrorMessage())
    }

};


export const letsChat = (lang) => async (dispatch, getState) => {
    try {
        dispatch(showRequestUI(FETCHING.INITIALIZING_CHAT))
        const response = await Api.startConversation(lang.id);
        if(response.data.start){
            const jsConfetti = new JSConfetti();
                jsConfetti.addConfetti();
            setTimeout(()=>{
                window.location = `${baseURL}chat`;
            },1000)   
        }
        dispatch(showRequestUI(false));
    } catch (error) {
        console.log(error);
       dispatch(showRequestError())
    }
};

export const getLang = () => async (dispatch, getState) => {
    try {
        dispatch(showRequestUI(true))
        let response = await Api.getLang();
        dispatch(displayLangs({langs:response.data.langs}));
        dispatch(showRequestUI(false));
    } catch (error) {
       dispatch(showRequestError())
    }
};
