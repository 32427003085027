
export const VIEW = {
    JOINUS:'JOINUS',
    TEST_FINISHED: 'TEST_FINISHED',
    DISPLAY_LANG: 'DISPLAY_LANG',
    NOT_NAME: 'NOT_NAME'
}

export const FETCHING = {
    INITIALIZING_CHAT:'INITIALIZING_CHAT'
};

export const USER = {
    SET_NAME: 'SET_NAME',
    SET_LANG: 'SET_LANG'
};