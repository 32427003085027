import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
        en: {
            translation:{
                'server.error':'Please try again later, we are experiencing very high demand',
                'ai.initializing':'Initilizing ai',
                'headline':'The #1 AI Learning Language App',
                'menu.howitworks':'How it works',
                'menu.id.howitworks':'How-it-works',
                'menu.usecases':'Use cases',
                'menu.id.usecases':'use-cases',
                'menu.faq':'FAQ',
                'menu.id.faq':'faq',
                'menu.price':'Price',
                'menu.id.price':'price',
                'cta.one':"Let's chat now",
                'cta.two':"Buy now",
                'cta.three':"Try it",
                'select.lang': 'Select language',
                'start.conversation':'Start Chatting',
                'signin': 'Signin',
                'buyplan': 'Buy a plan',
                'guidance': 'Guidance',
                'hint': 'What to say?',
                'hint.title':'Say it like this',
                'translate': 'Translate',
                'select.lesson': 'Select lesson',
                'checking.grammar': 'Checking grammar',
                'checking.grammar.option.one': 'Pronunciation is not correct',
                'checking.grammar.option.two': 'Pronunciation is  correct',
                'error.audio': 'Error while loading the audio',
                'signup':'Sign up',
                'signup.create.account':'Create an account',
                'signup.create.account.text':'Personalized learning, progress tracking, and accessing premium content.',
                'email.label':'Email address',
                'email.placeholder':'Enter your email',
                'password.label':'Password',
                'password.placeholder':'Enter your password',
                'already':'Already have an account?',
                'signin':'Sign In',
                'signin.text' :'Personalized learning, progress tracking, and accessing premium content.',
                'signin.welcome':'Welcome',
                'auth.continue': 'Continue',
                'auth.not.account':"Don't have an account?",
                'grammar':'Grammar',
                'user.profile':'Profile',
                'user.plan': 'Plan',
                'user.profile.text':'Personalized and secure user experience while ensuring proper communication and verification',
                'user.name.label': 'Name',
                'user.name.placeholder': 'Enter your name',
                'user.country.label':'Country',
                'user.country.placeholder':'Enter your country',
                'buttom.save.label':'Save',
                'security.label':'Security',
                'security.text':'Safeguarding  your personal and sensitive information',
                'password.confirm':'Confirm your password',
                'plan.text':"Our plan offers in-depth lessons, personalized feedback, and exclusive content, making your journey to fluency enjoyable and effective. Don't miss out on this chance to invest in your future. Start your learning journey today",
                'plan.upgrade.text':'Upgrade',
                'home.headline':"Improve 10x your fluency in any idioms, Talk about whatever you want, let's talk",
                'home.tryit':'Try it free',
                'e':'1',
                'home.headline.one':"Practice without a tutor,  improve",
                'home.headline.two': "your fluency in any language",
                'home.headline.three': "Unlock your tongue.",
                'home.headline.four': "Dive into ",
                'home.headline.x':'10x',
                'howitworks.title':'How does it works?',
                'howitworks.subtitle':'Just 3 steps',
                'howitworks.text':'Join more than 500+ people who use languages to their advantage',
                'howitworks.stepone':'Select the language',
                'howitworks.steptwo':"Click let's chat",
                'howitworks.stepthree':"Start chatting",
                'howitworks.title':'How does it works?',
                'usecases.title':'Use cases',
                'usecases.subtitle':'Practice in any scenario',
                'usecases.text': "Just 10 minutes a day can make a difference, We use interactive and fun activities to make learning easy and enjoyable, even for complex languages",
                'usecase.one.title': 'Arriving late to work',
                'usecase.one.description':'The employee is running late to work due to unforeseen circumstances such as traffic or public transportation delays and must inform their supervisor or manager of the delay and offer an apology.',
                'usecase.two.title': 'Party Invitation',
                'usecase.two.description':"Invites friends and family to a celebration to share food, drinks, and music in a festive and social atmosphere, enjoying each other's company.",
                'usecase.three.title': 'Attending a Medical Appointment',
                'usecase.three.description':' the patient describes their symptoms to the doctor and receives a diagnosis and treatment plan.',
                'usecase.four.title': 'At a Restaurant',
                'usecase.four.description':'People seated at a table, looking at the menu, and ordering food and drinks from the waiter.',
                'usecase.five.title': 'Talking About Your Habits',
                'usecase.five.description':'Conversing with friends about your habits. Share your daily routines, activities, and discuss personal preferences and how they impact your physical and mental well-being.',
                'usecase.six.title': 'At the Airport Check-In',
                'usecase.six.description':'At the airport check-in counter, travelers present their travel documents, check their luggage, and receive their boarding pass before boarding the plane, while undergoing the necessary security checks.',
                'price.title':'Price',
                'price.subtitle':'Take advantage of limited-time promotion!',
                'price.text':'Try it now, All plans have up to a 50% discount',
                'price.free.title':'Free',
                'price.free.optionone': '1 AI lesson maximum 20 minutes',
                'price.free.optiontwo': '1 random lesson',
                'price.free.optionthree': 'No appointment necessary',
                'price.basic.title':'Basic',
                'price.basic.optionone': '2 open practices',
                'price.basic.optiontwo': 'Practice for a maximum of 1 hour per lesson',
                'price.basic.optionthree': 'Priority support',
                'price.basic.optionfour':'Practice anytime',
                'price.basic.optionseven':'AI Instant feedback for pronunciation',
                'price.fluency.optionone':'Unlimited open practice',
                'price.fluency.optiontwo':'Unlimited practice time',
                'price.fluency.optionthree':'Schedule the lesson',
                'price.fluency.optionfour':'AI Instant feedback for pronunciation',
                'price.fluency.optionfive':'Pronunciation AI helper',
                'price.fluency.optionsix':'Access to latest features',
                'price.fluency.optionseven':'Certificates (soon)',
                'user':'User',
                'month':'Month',
                'tos':'Terms of Service',
                'faq.title':'FAQ',
                'faq.one.title':'I have a busy schedule. Can I fit language learning into my daily routine?',
                'faq.one.description':"Absolutely! Our app offers flexible lessons and practices that you can complete in just a few minutes a day. You can learn at your own pace, whether it's during your commute, lunch break, or before bed.",
                'faq.two.title':"How is my data, including voice recordings, handled by the app?",
                'faq.two.description':"We take your privacy seriously. Your data is securely stored, and we use it only to enhance your learning experience. You have control over your data and can delete it at any time.",
                'faq.three.title':'How does the app provide personalized feedback and guidance?',
                'faq.three.description':"Our AI analyzes your progress and adapts lessons to your skill level. You'll receive tailored recommendations and feedback to help you improve.",
                
                'faq.four.title':'How can I contact support if I have questions or need help?',
                'faq.four.description':"We're here to assist you! Feel free to reach out via email us at hola@speqy.co. Your satisfaction is our priority!",
                'faq.zero.title':'I stopped using the app. Can I request a refund?',
                'faq.zero.description':"Our refund policy typically covers a seven-day period,  If you have any questions or need assistance, please feel free to reach out to us via email",
                
                'select.lessons':'Select lesson',
                'error.recording':"Ups, We're sorry, but it seems there was an issue with the audio recording",
                'growth.minutes':'minutes',
                'growth.congrats.one':'Congratulations! You have spent ',
                'growth.congrats.two':' minutes practicing your English.',
                'growth.congrats.three': 'Share your achievement and challenge your friends to exceed you'
              }
        },
        es:{
            translation:{
              'server.error':'Por favor intentar más tarde, estamos teniendo una demanda muy alta',
              'ai.initializing':'Inicializando ia',
              'headline':'The #1 IA app para aprender idiomas',
              'menu.howitworks':'¿Cómo funciona?',
              'menu.id.howitworks': 'como-funciona',
              'menu.usecases': 'Casos de uso',
              'menu.id.usecases': 'casos-de-uso',
              'menu.faq': 'Preguntas frecuentes',
              'menu.id.faq': 'preguntas-frecuentes',
              'menu.price': 'Precio',
              'menu.id.price': 'precio',
              'cta.one': 'Ir a practicar',
              'cta.two':"Comprar",
              'cta.three':"Probar",
              'select.lang': 'Selecciona un idioma',
              'start.conversation': 'Iniciar conversación',
              'signin': 'Iniciar sesión',
              'buyplan': 'Comprar un plan',
              'guidance': 'Guía',
              'hint': '¿Que decir?',
              'hint.title': 'Dilo así',
              'translate': 'Traducir',
              'select.lesson': 'Seleccionar lección',
              'checking.grammar': 'Verificación de gramática',
              'checking.grammar.option.one': 'La pronunciación no es correcta',
              'checking.grammar.option.two': 'La pronunciación es correcta',
              'error.audio': 'Error al cargar el audio',
              'signup': 'Registrarse',
              'signup.create.account': 'Crear una cuenta',
              'signup.create.account.text': 'Aprendizaje personalizado, seguimiento de progreso y acceso a contenido premium.',
              'email.label': 'Dirección de correo electrónico',
'email.placeholder': 'Ingresa tu correo electrónico',
'password.label': 'Contraseña',
'password.placeholder': 'Ingresa tu contraseña',
'already': '¿Ya tienes una cuenta?',
'signin': 'Iniciar sesión',
'signin.text': 'Experiencia personalizada y segura para el usuario mientras se asegura una comunicación y verificación adecuadas.',
'signin.welcome': 'Bienvenido',
'auth.continue': 'Continuar',
'auth.not.account': '¿No tienes una cuenta?',
'grammar': 'Gramática',
'user.profile': 'Perfil',
'user.plan': 'Plan',
'user.profile.text': 'Experiencia de usuario personalizada y segura mientras se asegura una comunicación y verificación adecuadas.',
'user.name.label': 'Nombre',
'user.name.placeholder': 'Ingresa tu nombre',
'user.country.label': 'País',
'user.country.placeholder': 'Ingresa tu país',
'buttom.save.label': 'Guardar',
'security.label': 'Seguridad',
'security.text': 'Protegiendo tu información personal y sensible',
'password.confirm': 'Confirma tu contraseña',
'plan.text': 'Nuestro plan ofrece lecciones detalladas, retroalimentación personalizada y contenido exclusivo, haciendo que tu viaje hacia la fluidez sea agradable y efectivo. No te pierdas esta oportunidad de invertir en tu futuro. Comienza tu viaje de aprendizaje hoy mismo.',
'plan.upgrade.text': 'Mejora',
'home.headline': 'Aumenta 10 veces tu fluidez en cualquier idioma, habla de lo que quieras, empecemos a chatear',
'home.tryit': 'Pruébalo gratis',
'e': '1',
'home.headline.one': 'Practica sin tutor y mejora',
'home.headline.two': 'tu fluidez en cualquier idioma',
'home.headline.three': 'Desbloquea tu lengua.',
'home.headline.four': 'Sumérgete en',
'home.headline.x': '10x',
'howitworks.title': '¿Cómo funciona?',
'howitworks.subtitle': 'Solo 3 pasos',
'howitworks.text': 'Únete a más de 500+ personas que utilizan los idiomas a su favor',
'howitworks.stepone': 'Selecciona el idioma',
'howitworks.steptwo': 'Haz clic en "empecemos a chatear"',
'howitworks.stepthree': 'Comienza a chatear',
'usecases.title': 'Casos de uso',
'usecases.subtitle': 'Practica en cualquier escenario',
'usecases.text': 'Tan solo 10 minutos al día pueden marcar la diferencia. Utilizamos actividades interactivas y divertidas para que el aprendizaje sea fácil y agradable, incluso para idiomas complejos.',
'usecase.one.title': 'Llegando tarde al trabajo',
'usecase.one.description': 'El empleado llega tarde al trabajo debido a circunstancias imprevistas, como el tráfico o retrasos en el transporte público, y debe informar a su supervisor o jefe sobre el retraso y ofrecer una disculpa.',
'usecase.two.title': 'Invitación a una fiesta',
'usecase.two.description': 'Invita a amigos y familiares a una celebración para compartir comida, bebida y música en un ambiente festivo y social, disfrutando de la compañía de los demás.',
'usecase.three.title': 'Asistiendo a una consulta médica',
'usecase.three.description': 'El paciente describe sus síntomas al médico y recibe un diagnóstico y tratamiento.',
'usecase.four.title': 'En un restaurante',
'usecase.four.description': 'Personas sentadas en una mesa, mirando el menú y pidiendo comida y bebidas al mesero.',
'usecase.five.title': 'Hablando con amigos sobre tus hábitos',
'usecase.five.description': 'Conversación con amigos sobre tus hábitos diarios, rutinas y actividades, discutiendo tus preferencias personales y cómo influyen en tu bienestar físico y mental.',
'usecase.six.title': 'En el check-in del aeropuerto',
'usecase.six.description': 'Los viajeros presentan su documentación de viaje, facturan su equipaje y reciben su tarjeta de embarque antes de abordar el avión, mientras se someten a los controles de seguridad correspondientes.',
'price.title': 'Precio',
'price.subtitle': '¡Aprovecha la promoción por tiempo limitado!',
'price.text': 'Pruébalo ahora, Todos los planes tienen hasta un 50% de descuento',
'price.free.title': 'Gratis',
'price.free.optionone': '1 lección de IA máxima de 20 minutos',
'price.free.optiontwo': '1 lección aleatoria',
'price.free.optionthree': 'No es necesario agendar',
'price.basic.title': 'Básico',
'price.basic.optionone': '2 prácticas abiertas',
'price.basic.optiontwo': 'Practica hasta 1 hora por lección',
'price.basic.optionthree': 'Soporte prioritario',
'price.basic.optionfour': 'Practica en cualquier momento',
'price.basic.optionseven': 'Comentarios instantáneos de IA para la pronunciación',
'price.fluency.optionone': 'Práctica abierta ilimitada',
'price.fluency.optiontwo': 'Tiempo de práctica ilimitado',
'price.fluency.optionthree': 'Agenda la lección',
'price.fluency.optionfour': 'Comentarios instantáneos de IA para la pronunciación',
'price.fluency.optionfive': 'Asistente de IA para la pronunciación',
'price.fluency.optionsix': 'Acceso a las últimas características',
'price.fluency.optionseven': 'Certificados (próximamente)',
'user': 'Usuario',
'month': 'Mes',
'tos': 'Términos de Servicio',
'faq.title': 'Preguntas frecuentes',
'faq.one.title': 'Tengo una agenda ocupada. ¿Puedo incorporar el aprendizaje de idiomas en mi rutina diaria?',
'faq.one.description': '¡Absolutamente! Nuestra aplicación ofrece lecciones y prácticas flexibles que puedes completar en solo unos minutos al día. Puedes aprender a tu propio ritmo, ya sea durante tu viaje, el almuerzo o antes de dormir.',
'faq.two.title': '¿Cómo maneja la aplicación mis datos, incluidas las grabaciones de voz?',
'faq.two.description': 'Nos tomamos tu privacidad en serio. Tus datos se almacenan de manera segura y los utilizamos solo para mejorar tu experiencia de aprendizaje. Tienes control sobre tus datos y puedes eliminarlos en cualquier momento.',
'faq.three.title': '¿Cómo proporciona la aplicación retroalimentación y orientación personalizada?',
'faq.three.description': 'Nuestra IA analiza tu progreso y adapta las lecciones a tu nivel de habilidad. Recibirás recomendaciones y retroalimentación personalizadas para ayudarte a mejorar.',
'faq.four.title': '¿Cómo puedo ponerme en contacto con el soporte si tengo preguntas o necesito ayuda?',
'faq.four.description': '¡Estamos aquí para ayudarte! No dudes en ponerte en contacto con nosotros por correo electrónico a hola@speqy.co. ¡Tu satisfacción es nuestra prioridad!',
'faq.zero.title': 'Dejé de usar la aplicación. ¿Puedo solicitar un reembolso?',
'faq.zero.description': 'Nuestra política de reembolso generalmente cubre un período de siete días. Si tienes alguna pregunta o necesitas ayuda',
'select.lessons':'Seleccionar lección',
'error.recording':'Lo sentimos, pero parece que hubo un problema con la grabación de audio.',
'growth.minutes':'minuto(s)',
                'growth.congrats.one':'Felicitaciones! Has dedicado ',
                'growth.congrats.two':' más de minutos a practicar tu ',
                'growth.congrats.three': 'Comparte tu logro y desafía a tus amigos a superarte'
            }
        }
    }
  });

export default i18n;