
import React from "react";

export default function Container(props) {
  return (
    <div
      className={`container relative py-5 md:py-10 mx-auto xl:px-0 ${
        props.className ? props.className : ""
      }`}>
      {props.children}
    </div>
  );
} 